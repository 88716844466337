@font-face {
  font-family: "UniversNext";
  src: url("./assets/fonts/UniversNextforHSBCW29-UltLt.eot?#iefix");
  src: url("./assets/fonts/UniversNextforHSBCW29-UltLt.eot?#iefix") format("eot"),
  url("./assets/fonts/UniversNextforHSBCW29-UltLt.woff") format("woff"),
  url("./assets/fonts/UniversNextforHSBCW29-UltLt.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "UniversNext";
  src: url("./assets/fonts/UniversNextforHSBCW29-Thin.eot?#iefix");
  src: url("./assets/fonts/UniversNextforHSBCW29-Thin.eot?#iefix") format("eot"),
  url("./assets/fonts/UniversNextforHSBCW29-Thin.woff") format("woff"),
  url("./assets/fonts/UniversNextforHSBCW29-Thin.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "UniversNext";
  src: url("./assets/fonts/UniversNextforHSBCW29-ThIt.eot?#iefix");
  src: url("./assets/fonts/UniversNextforHSBCW29-ThIt.eot?#iefix") format("eot"),
  url("./assets/fonts/UniversNextforHSBCW29-ThIt.woff") format("woff"),
  url("./assets/fonts/UniversNextforHSBCW29-ThIt.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "UniversNext";
  src: url("./assets/fonts/UniversNextforHSBCW29-Light.eot?#iefix");
  src: url("./assets/fonts/UniversNextforHSBCW29-Light.eot?#iefix") format("eot"),
  url("./assets/fonts/UniversNextforHSBCW29-Light.woff") format("woff"),
  url("./assets/fonts/UniversNextforHSBCW29-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "UniversNext";
  src: url("./assets/fonts/UniversNextforHSBCW29-LtIt.eot?#iefix");
  src: url("./assets/fonts/UniversNextforHSBCW29-LtIt.eot?#iefix") format("eot"),
  url("./assets/fonts/UniversNextforHSBCW29-LtIt.woff") format("woff"),
  url("./assets/fonts/UniversNextforHSBCW29-LtIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "UniversNext";
  src: url("./assets/fonts/UniversNextforHSBCW29-Rg.eot?#iefix");
  src: url("./assets/fonts/UniversNextforHSBCW29-Rg.eot?#iefix") format("eot"),
  url("./assets/fonts/UniversNextforHSBCW29-Rg.woff") format("woff"),
  url("./assets/fonts/UniversNextforHSBCW29-Rg.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "UniversNext";
  src: url("./assets/fonts/UniversNextforHSBCW29-Md.eot?#iefix");
  src: url("./assets/fonts/UniversNextforHSBCW29-Md.eot?#iefix") format("eot"),
  url("./assets/fonts/UniversNextforHSBCW29-Md.woff") format("woff"),
  url("./assets/fonts/UniversNextforHSBCW29-Md.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "UniversNext";
  src: url("./assets/fonts/UniversNextforHSBCW29-Bold.eot?#iefix");
  src: url("./assets/fonts/UniversNextforHSBCW29-Bold.eot?#iefix") format("eot"),
  url("./assets/fonts/UniversNextforHSBCW29-Bold.woff") format("woff"),
  url("./assets/fonts/UniversNextforHSBCW29-Bold.ttf") format("truetype");
  font-weight: 700;
}