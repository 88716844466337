@import './UniversNextforHSBC.font.scss';

* {
    box-sizing: border-box;
}

/* TScale: 1 */
:root {
    --primary-color: #db0011;
    --light-gray: #d7d8d6;

    --error-bg-color: #f9f2f3;
    --error-border-color: #a8000b;
    --warning-border-color: #FFBB33;
    --success-border-color: #00847F;

    --text-gray-color: #333333;
    --black-color: #000000;
    --white-color: #ffffff;

    --btn-hover-bg: #F1F3F4;
    --btn-press-bg: #C9D0D5;
    --btn-primary-hover-bg: #AF000D;
    --btn-primary-press-bg: #83000A;
    
    --f4: 0.25rem;
    --f8: 0.5rem;
    --f9: 0.5625rem;
    --f11: 0.6875rem;
    --f12: 0.75rem;
    --f13: 0.8125rem;
    --f15: 0.9375rem;
    --f16: 1rem;
    --f20: 1.25rem;
    --f44: 2.75rem;
    --f60: 3.75rem;

    --s1: 2.063rem;
    --s2: 1.750rem;
    --s3: 1.438rem;
    --s4: 1.188rem;
    --s5: 1rem;
    --s6: 0.875rem;

    --r3: 0.75rem;
    --r4: 1rem;
    --r5: 1.25rem;

    --font-family: 'UniversNext';

    --logo-width: 110px;
    --table-header-bg: #E4E8EA;
    --table-cell-v-border-color: #F1F3F4;
    --table-cell-h-border-color: #C9D0D5;

    --common-transition: all 0.3s;
    --border-radius: 0.5rem;
}

// @media all and (min-width: 960px) {
//     :root {
//         --s1: 2.750rem;
//         --s2: 2.188rem;
//         --s3: 1.750rem;
//         --s4: 1.375rem;
//         --s5: 1rem;
//     }
// }

html, body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family);
    font-weight: normal;
}
h1 {
    font-size: var(--s2);
    font-weight: normal;
    border-left: 3px solid var(--primary-color);
    padding-left: var(--f20);
}
button {
    min-height: var(--f44);
    font-family: var(--font-family);
    font-size: var(--s5);
    padding: var(--f9) var(--f20) var(--f11) var(--f20);
    border: 1px solid var(--black-color);
    color: var(--black-color);
    background-color: var(--white-color);
    margin: var(--f15) var(--f8);

    &:hover:not(:disabled) {
        background-color: var(--btn-hover-bg);
    }
    &:active:not(:disabled) {
        background-color: var(--btn-press-bg);
    }
}

button.primary:not(:disabled) {
    color: var(--white-color);
    background-color: var(--primary-color);
    border: none;

    &:hover {
        background-color: var(--btn-primary-hover-bg);
    }
    &:active {
        background-color: var(--btn-primary-press-bg);
    }
}

button:disabled {
    color: var(--text-gray-color) !important;
    background-color: none !important;
    border: 1px solid var(--text-gray-color) !important;
    opacity: 0.3 !important;
}

.container {
    max-width: 1450px;
    margin-left: auto;
    margin-right: auto;
}
.form-container {
    margin-left: calc(-1 * var(--f15));
}

table {
    width: 100%;

    th,td {
        padding: var(--f9) var(--f16) var(--f11) var(--f16);
    }
    th {
        text-align: left;
        background: var(--table-header-bg);
    }
    tr {
        border-bottom: 1px solid var(--table-cell-h-border-color);
        display: flex;
        justify-content: space-between;
    }
    td {
        border-left: 1px solid var(--table-cell-v-border-color);
        border-right: 1px solid var(--table-cell-v-border-color);

        button {
            margin: 2px;
            min-height: 22px;
        }

        // classes for directoryStatus
        &.Active {
            color: var(--success-border-color);
        }
        &.Inactive {
            color: var(--warning-border-color);
        }
        &.Deleted {
            color: var(--error-border-color);
        }
    }
}

section.text-input, section.role-input, .modify-tsp-list-container, section.select {
    display: flex;
    flex-direction: column;
    margin: var(--f15);
    
    label {
        font-size: var(--s5);
        margin-bottom: var(--f8);

        .asterisk {
            color: var(--error-border-color);
        }
    }
    input[type='text'], select {
        min-height: var(--f44);
        padding: var(--f9) var(--f12) var(--f11) var(--f12);
        font-size: var(--s5);
        font-family: var(--font-family);
    }

    .error {
        display: none;
        font-size: var(--s6);
        margin-top: var(--f8);

        i.error-icon {
            margin-right: var(--f4);
            display: inline-block;
            width: var(--s6);
            background-image: url(/assets/images/status_error_onlight.svg);
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
.d-none {
    display: none !important;
}
.action-area {
    text-align: right;
    margin: 0 var(--f8);
}

.stop-scroll {
    overflow-y: hidden;
}

app-text-input, app-select {

    &.ng-invalid.ng-touched {
        
        .form-control > input, .form-control > select {
            border: 1px solid var(--error-border-color);
            background-color: var(--error-bg-color);

            option {
                white-space: pre-wrap;
            }
        }
    
        .error {
            display: flex;
        }
    }
}

app-psp-role-input.ng-invalid.ng-touched {
    table {
        border: 1px solid var(--error-border-color);
    }
    .error {
        display: flex;
    }
}

.form-container {
    display: flex;
    flex-wrap: wrap;

    app-text-input, app-select {
        flex: 0 1 50%;
    }
    app-psp-role-input {
        flex: 1 1 100%;
    }
}